.confirm {
    margin-top: 1.5em;
}

.code {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    //display: flex;
    //justify-content: flex-start;
}

.digitInput {
    width: 3.5em;
    display: inline-block;
    margin-right: 1em;

    input {
        text-align: center;
    }
}