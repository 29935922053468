/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/next/dist/build/webpack/loaders/css-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[1]!./node_modules/next/dist/build/webpack/loaders/postcss-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[2]!./node_modules/next/dist/compiled/resolve-url-loader/index.js??ruleSet[1].rules[2].oneOf[6].use[3]!./node_modules/next/dist/compiled/sass-loader/cjs.js??ruleSet[1].rules[2].oneOf[6].use[4]!./src/components/authentication/code-confirm/code-confirm.module.scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.code-confirm_confirm__ZRYI3 {
  margin-top: 1.5em;
}

.code-confirm_code__9uiv8 {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.code-confirm_digitInput__3J_GR {
  width: 3.5em;
  display: inline-block;
  margin-right: 1em;
}
.code-confirm_digitInput__3J_GR input {
  text-align: center;
}
/*!**************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/next/dist/build/webpack/loaders/css-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[1]!./node_modules/next/dist/build/webpack/loaders/postcss-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[2]!./node_modules/next/dist/compiled/resolve-url-loader/index.js??ruleSet[1].rules[2].oneOf[6].use[3]!./node_modules/next/dist/compiled/sass-loader/cjs.js??ruleSet[1].rules[2].oneOf[6].use[4]!./src/components/authentication/code-resend/style.module.scss ***!
  \**************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.style_resend__21AsL {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}
.style_resend__21AsL p {
  cursor: pointer;
  color: #1F64E5;
}
.style_resend__21AsL span {
  margin-left: 1.5em;
  color: #9FA4AC;
}
/*!***********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/next/dist/build/webpack/loaders/css-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[1]!./node_modules/next/dist/build/webpack/loaders/postcss-loader/src/index.js??ruleSet[1].rules[2].oneOf[6].use[2]!./node_modules/next/dist/compiled/resolve-url-loader/index.js??ruleSet[1].rules[2].oneOf[6].use[3]!./node_modules/next/dist/compiled/sass-loader/cjs.js??ruleSet[1].rules[2].oneOf[6].use[4]!./src/components/login/types/style.module.scss ***!
  \***********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.style_types__1wfyo {
  width: 30%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  margin-bottom: 1em;
}

.style_icon__29hr7 {
  border: 2px solid #D1D5DB;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding: 0.4em;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0.5em;
  cursor: pointer;
  margin: 0.6em;
}
