.resend {
  display: flex;
  align-items: center;

  p {
    cursor: pointer;
    color: #1F64E5;
  }

  span {
    margin-left: 1.5em;
    color: #9FA4AC;
  }
}